import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import newsStyles from "../styles/pages/news.module.css"

const NewsPage = () => (
    <Layout location="news">
      <SEO title="News" />
      <div className={ newsStyles.grid }>
      <div className={ newsStyles.left }>
        <div className={ newsStyles.titleSection }>
          <div className={ newsStyles.noNews }>
            <h1>There are no articles.</h1>
            <h3>Stay tuned for updates on our latest partnerships.</h3>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
          </div>
        </div>
      </div>
      <div className={ newsStyles.right }>
      </div>
    </div>
    </Layout>
)

export default NewsPage